import React,{Component} from 'react';
import '../Home/Home.css';


const contUrl = "https://mallofdubaiapi.onrender.com/contact"

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:'',
            email:'',
            phone:'',
            Address:'',
            Subject:'',
            Message:'',

        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    async handleSubmit() {
        
        try {
            let result = await fetch(contUrl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    this.state
                )
            });
            
            console.log('result>  ' + result)
            alert("Message Submitted Successfully")
            this.props.history.push('/')   
            
            
        } catch(e) {
            console.log(e)
        }
    }

    render(){                                                                                
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className ="col-6 aboutfmt2">
                            <p className="formttxt3">Contact Us</p>
                            <p className="formttxt4">Plot 158, Gimbiya Street, Area 11, Garki, Abuja, Nigeria</p>
                            <p className="formttxt5">info@mallofdubailtd.com</p>
                            <p className="formttxt5">+234 913 600 0483, +234 913 600 0484</p>
                        </div>
                        <div className ="col-6 aboutfmt">
                        <label className='form-label'>First Name</label>
                            <input type="text" className="form-control mb-3" name="name" value={this.state.name} onChange={this.handleChange}/>
                            <label className='form-label'>Last Name</label>
                            <input type="email" className="form-control mb-3" name="email" value={this.state.email} onChange={this.handleChange}/>
                            <label className='form-label'>Phone Number</label>
                            <input type="number" className="form-control mb-3" name="phone" value={this.state.phone} onChange={this.handleChange}/>
                            <label className='form-label'>Address</label>
                            <input type="text" className="form-control mb-3" name="Address" value={this.state.Address} onChange={this.handleChange}/>
                            <label className='form-label'>Subject</label>
                            <input type="text" className="form-control mb-3" name="Subject" value={this.state.Subject} onChange={this.handleChange}/>
                            <label className='form-label'>Message</label>
                            <textarea className="form-control mb-3" rows="4" name="Message" value={this.state.Message} onChange={this.handleChange}/>
                            <center><button type="button" class="btn btn-secondary" onClick={ () => this.handleSubmit() }>Submit</button></center>
                        </div>
                                    
                    </div>
                </div>
            
            </>
        )
    }

}
export default Contact;
import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Home from './Home/Home';
import Contact from './Booking/Contact';
import RoomSearch from './Home/RoomSearch';
import Explore from './Gallery/Explore';


const Routing = () => {
    return(
        <BrowserRouter>
            <Header/>
            <Route exact path="/" component={Home}/>
            <Route path="/Contact" component={Contact}/>
            <Route path="/About" component={RoomSearch}/>
            <Route path="/Gallery" component={Explore}/>
            <Footer/>
        </BrowserRouter>
    )
}

export default Routing;

import React,{Component} from 'react';
import './Home.css';
import {Link} from 'react-router-dom';
import ReactPlayer from 'react-player';





const roomsurl = "https://mallofdubaiapi.onrender.com/homecarousel"

class RoomsDisplay extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            display:''
        }
    }


    renderRooms = (data) => {
        if (data){
            return data.map((item, index) => {
                return(
                    <>
                        <div className = "row rowbg" key={index}>
                            <div className ="col-3 framesize1">
                                <img className="framesize1" src = {item.image6} alt="display"/>
                            </div>
                            <div className ="col-3 framesize2">
                                <center>
                                    <p className="wordfmt">Development</p>
                                    <p className="wordfmt2">It's All in the Details</p>

                                </center>
                            </div>
                            <div className ="col-3 framesize3">
                                <img className="framesize3" src = {item.image2} alt="display"/>
                            </div>
                            <div className ="col-3 framesize4">
                                <center>
                                    <p className="wordfmt">Interior Design</p>
                                    <p className="wordfmt2">Smart Interior</p>

                                </center>
                            </div>
                            
                        </div>
                        <div className = "row rowbg" key={index}>
                            <div className ="col-3 framesize5 shadex">
                                <p className="wordfmt5">Construction</p>
                                <p className="wordfmt6">Efficient & Reliable</p>

                            </div>
                            
                            <div className ="col-3 framesize6">
                                <div className ="col-3 framesize6">
                                    <img className="framesize1" src = {item.image5} alt="display"/>
                                </div>
                            </div>
                            <div className ="col-3 framesize7">
                            <center>
                                    <p className="wordfmt7">Location</p>
                                    <p className="wordfmt8">Abuja, Nigeria</p>

                                </center>
                              
                            </div>
                            <div className ="col-3 framesize8">
                                <img className="framesize8" src = {item.image7} alt="display"/>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className ="col-6 aboutfmt2">
                                <p className="formttxt3">About</p>
                                <p className="formttxt4">Since its inception in 2021, mallofdubailtd.com has earned its reputation as a leading Property Development Company in and around Nigeria. We pride ourselves on developing smart, high-tech buildings, always with a sustainable footprint. Working with a strong team of industry professionals, we have a proven track record in unlocking value in sites and translating client and investor visions into reality. To learn more about our company profile, get in touch today.</p>
                                <p className="formttxt5">Some of our projects include Hotel Development, Mall development, Residential Structures and Lots More.</p>
                                <p className="formttxt5">Get in touch today !</p>
                                <Link to='/Contact'><center><button type="button" class="btn btn-outline-secondary">Get in Touch</button></center></Link>
                            </div>
                            <div className ="col-6 aboutfmt">
                                <img className="aboutfmt"src="https://i.ibb.co/fXNNvH5/11062b-69602ebf1eb64cb98131a93b5e7b0f58-mv2.jpg" alt="about"/>
                            </div>
                            
                        </div>
                        <center>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url='https://youtu.be/WT2KrmOH4gU'
                                    width='840px'
                                    height='560px'
                                    playing='true'
                                    controls='true'
                                    loop='true'

                                />
                            </div>
            
                    </center>

                        

                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }


    render(){
        
        return(
            <>
                <div className="container">
                    <center>
                        <h3 className="text">
                            Mall of Dubai, Abuja, Nigeria
                        </h3>
                        <p className="text2">What We Offer</p>
                    
                    </center>
                    <div className="row">
                        <div className ="col">
                            {this.renderRooms(this.state.display)}
                        </div>
                       
                    </div>
                    
                </div>
            
            </>
            
        )
    }

    componentDidMount(){
        
        fetch(roomsurl, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({display:data})
        })

    }
}

export default RoomsDisplay;
import React,{Component} from 'react';
import './Home.css';





const slideUrl = "https://mallofdubaiapi.onrender.com/homecarousel";

class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        console.log (">>>Inside constructor")
        this.state={
            images:'',
            
        }
    }

      
    renderSlide = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <div id ="carouselExampleControls" className="carousel slide slider2" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image1} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image2} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image3} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image4} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image5} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image6} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image7} className="d-block w-100 ht slider2" alt="slides"/>
    
                            </div>
                                                        
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
    
                    </div>
                )
            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }            
        
    }
    render(){
        console.log (">>>Inside render",this.state)
        return(
            <>
                <div className="slider">
                    {this.renderSlide(this.state.images)}
                </div>
                <div className="row">
                    <center>
                        <div className="col-8">
                            <p className="formttxt">mallofdubailtd.com</p>
                            <p className="formttxt2">Welcome to mallofdubailtd.com, a Property Development Company with the sole purpose of working on projects that are both sustainable and made to last. Built upon a strong financial foundation, we’re highly regarded throughout Nigeria for our unwavering commitment to quality, as well as our creative use of building materials to help keep costs low. Get in touch today to find out more about our company’s history and everything we have planned for The Mall of Dubai.</p>
                        </div>
                    </center>
                </div>
                
            </>
            
        )
    }

    componentDidMount(){
        console.log (">>>Inside didMount")
        fetch(slideUrl, {method:'GET'} )
        .then((res) => res.json())
        .then((data) => {
            this.setState({images:data})
        })
    }
}

export default HomeCarousel;


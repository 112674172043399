import React from 'react';
import ReactPlayer from 'react-player';
import './Gallery.css';

const Explore = () => {

    return(
        <>
            <center>
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/KHH-pnZ87KU'
                        width='840px'
                        height='560px'
                        playing='true'
                        controls='true'
                        loop='true'

                    />
                </div>
            
            </center>
        </>
    )
}
export default Explore;
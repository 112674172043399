import React from 'react';
import './Home.css';
import {Link} from 'react-router-dom';

const RoomSearch = () => {

    return(
        <>
            <div className="row">
                <div className ="col-6 aboutfmt5">
                    <p className="formttxt3">All About Us</p>
                    <p className="formttxt4">mallofdubailtd.com was founded in 2021 with a single mission: to build a smart mall with enduring value and the potential to become timeless. Our team of creative professionals carefully evaluated this project in order to adhere to both financial and time constraints. We present Mall of Dubai in Nigeria</p>
                    <p className="formttxt5">If you’re looking for that experience of Dubai in Nigeria, consider mallofdubailtd.com and watch out for more information from us. We have the expertise and the desire to provide you with luxury.</p>
                    <Link to='/Gallery'><center><button type="button" class="btn btn-outline-secondary">Learn More</button></center></Link>
                </div>
                <div className ="col-6 aboutfmt3">
                    <img className="aboutfmt3"src="https://i.ibb.co/VS6wkJH/11062b-8883140b239a4e3b8d852eff15415352-mv2.webp" alt="about"/>
                </div>
                            
            </div>
        </>
    )
}
export default RoomSearch;
import React from 'react';
import './Header.css';


const Footer = () => {
    return(
        <div style={{width:'100%',display:'inline-block',color:"black"}}>
            <hr/>
            <div>
                <center>
                    <h6>&copy; Mall of Dubai Ltd&nbsp;({new Date().getFullYear()})</h6>
                    <i className="fa fa-map-marker"></i> &nbsp;Plot 158, Gimbiya Street, Area 11 Garki, Abuja, Nigeria.
                    <p><i className="fa fa-phone"></i>&nbsp;+234 913 600 0483 |  +234 913 600 0484</p>
                    <div>
                        <span classname="twitr">
                            <a href="http://www.twitter.com" target='_blank' rel="noopener noreferrer" aria-label='twitter'>
                                <i class="fab fa-twitter"></i>
                            </a>
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="facebk">
                            <a href="http:www.facebook.com" target='_blank' rel="noopener noreferrer" aria-label='facebook'>
                                <i class="fab fa-facebook"></i>
                            </a>
                            
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="insta">
                            <a href="http://www.instagram.com" target='_blank' rel="noopener noreferrer" aria-label='insta'>
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                    </div>
                </center>
                
            </div>
        </div>
    )
}

export default Footer;